import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import MusicRepository from "@/abstraction/repository/musicRepository";
const repository = new MusicRepository();

export default function useUsersList() {
    // Use toast
    const toast = useToast();

    const refmusicListTable = ref(null);
    const musics = ref([]);
    const stageMusics = ref([]);
    const pagination = ref([]);

    // Table Handlers
    const tableColumns = [{ key: "music", sortable: true }, { key: "genre", sortable: true }, { key: "artist", sortable: true }, { key: "share_count", sortable: true }, { key: "actions" }];
    const stageTableColumns = [{ key: "music", sortable: true }, { key: "genre", sortable: true }, { key: "artist", sortable: true }, { key: "actions" }];
    const perPage = ref(10);
    const totalUsers = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const roleFilter = ref(null);
    const planFilter = ref(null);
    const statusFilter = ref(null);
    const stageId = ref();
    const refetchData = () => {
        refmusicListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
        refetchData();
    });

    const fetchMusics = async (paginate = { page: 1, itemsPerPage: perPage.value }, filters = {}) => {
        const musicsList = await repository.stageSongsindex({ filters, pagination: paginate }, stageId.value);
        musics.value = musicsList.data;
        stageMusics.value = musicsList.data.songs;
        pagination.value = musicsList.pagination;
        return musics;
    };

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveUserRoleVariant = (role) => {
        if (role === "subscriber") return "primary";
        if (role === "author") return "warning";
        if (role === "maintainer") return "success";
        if (role === "editor") return "info";
        if (role === "admin") return "danger";
        return "primary";
    };

    const resolveUserRoleIcon = (role) => {
        if (role === "subscriber") return "UserIcon";
        if (role === "author") return "SettingsIcon";
        if (role === "maintainer") return "DatabaseIcon";
        if (role === "editor") return "Edit2Icon";
        if (role === "admin") return "ServerIcon";
        return "UserIcon";
    };

    const resolveUserStatusVariant = (status) => {
        if (status === "pending") return "warning";
        if (status === "active") return "success";
        if (status === "inactive") return "secondary";
        return "primary";
    };

    return {
        fetchMusics,
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refmusicListTable,
        musics,
        pagination,
        stageTableColumns,

        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,
        refetchData,
        stageId,
        stageMusics,
        // Extra Filters
        roleFilter,
        planFilter,
        statusFilter,
    };
}
